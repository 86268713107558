import React, { FunctionComponent } from "react";
import * as Styled from "./Button.styled";
import { ColorType } from "../../@types/ColorType";

interface ButtonType {
  text: string;
  disabled?: boolean;
  color?: ColorType;
  type?: any;
  onClick?: () => void;
}

const Button: FunctionComponent<ButtonType> = ({
  text,
  disabled = false,
  type = "button",
  color = "lime",
  onClick,
}) => {
  return (
    <Styled.Button
      type={type}
      disabled={disabled}
      color={color}
      onClick={onClick}
      ariaLabel={text}
    >
      {text}
    </Styled.Button>
  );
};

export default Button;
