import styled, { css } from "styled-components";
import ThemeStyle from "../../../styles/ThemeStyle";
import { mediaDown } from "../../../styles/Mixins";
import { ErrorText } from "../../../components/Form/Form.styled";

export const Form = styled.form`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding-top: 12rem;
  z-index: 5;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      padding-top: 6rem;
    `
  )}
`;

type FormProps = {
  type?: "formPage";
  color?: "white" | "black";
};

export const ContentWrapper = styled.div<FormProps>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5rem 8rem;
  padding-bottom: 6rem;

  div:nth-of-type(3),
  div:nth-of-type(6) {
    grid-column: 2 span;
  }

  ${(p: FormProps) =>
    p.type === "formPage" &&
    css`
      div:nth-of-type(3),
      div:nth-of-type(6) {
        grid-column: 1 span;
      }
    `};
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      grid-template-columns: 1fr;

      div:nth-of-type(3),
      div:nth-of-type(6) {
        grid-column: 1 span;
      }

      ${(p: FormProps) =>
        p.type === "formPage" &&
        css`
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 5rem;
        `};
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      grid-template-columns: 1fr;

      div:nth-of-type(3),
      div:nth-of-type(6) {
        grid-column: 1 span;
      }
    `
  )}
`;

export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      grid-template-columns: 1fr;
    `
  )}
`;

export const SingleButtonWrapper = styled.div`
  margin-top: 4rem;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      margin-top: 2rem;
      margin-bottom: 2rem;
    `
  )}
`;

export const ErrorMessage = styled(ErrorText)`
  position: relative;
  margin-top: 2rem;
  font-size: 2.2rem;
  text-align: center;
  transition: ${ThemeStyle.transition};
  transition-property: opacity, visibility;
`;

const InfoBase = css`
  width: 100%;
  text-align: left;
  font-size: 1.2rem;
  color: ${ThemeStyle.colors.white};
  margin: 1rem;

  a {
    transition: ${ThemeStyle.transition};
    transition-property: color;
    font-weight: ${ThemeStyle.fontWeight.bold};

    &:hover {
      color: ${ThemeStyle.colors.lime};
    }
  }
`;

export const MainInfo = styled.p<FormProps>`
  ${InfoBase};
  ${(p: FormProps) =>
    p.color === "black" &&
    css`
      color: ${ThemeStyle.colors.black};
    `};
`;

export const MainInfoCheckboxes = styled.div`
  position: relative;
  padding-left: 2.5rem;

  div:first-of-type {
    margin-top: 0;
  }

  label {
    p {
      transform: translateY(-3px);
    }
  }
`;

export const Info = styled.p<FormProps>`
  ${InfoBase};
  padding-left: 2.5rem;
  margin: 1rem 0 3rem;
  ${(p: FormProps) =>
    p.color === "black" &&
    css`
      color: ${ThemeStyle.colors.black};
    `};
`;

export const Error = styled(ErrorText)`
  left: 2.5rem;
  font-size: 1.4rem;
  transform: translateY(1.7rem);
`;
