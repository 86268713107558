import styled, { css } from "styled-components";
import ThemeStyle from "../../../styles/ThemeStyle";
import { ErrorText, LabelText } from "../Form.styled";
import { mediaDown } from "../../../styles/Mixins";

type InputProps = {
  state: "typing" | "error" | "valid" | "empty";
  filled: boolean;
  color?: "white" | "black";
};

export const InputWrapper = styled.div`
  position: relative;
`;

export const Input = styled.input<InputProps>`
  width: 100%;
  font-size: 2rem;
  font-family: ${ThemeStyle.fonts.main};
  color: ${ThemeStyle.colors.white};
  padding: 5px 0 5px 0;
  border-bottom: 1px solid ${ThemeStyle.colors.white};
  ${(props) =>
    props.color === "black" &&
    css`
      color: ${ThemeStyle.colors.black};
      border-color: ${ThemeStyle.colors.black};
    `}
  ${(props) =>
    props.state === "error" &&
    css`
      border-color: ${ThemeStyle.colors.error};
    `}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 1.8rem;
    `
  )};
`;

export const Label = styled(LabelText)<InputProps>`
  color: ${ThemeStyle.colors.white};
  font-size: 2rem;
  ${(props) =>
    props.color === "black" &&
    css`
      color: ${ThemeStyle.colors.black};
    `}
  ${(props) =>
    props.filled &&
    css`
      transform: translateY(-2rem);
      font-size: 1.2rem;
    `}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 1.6rem;
      ${(props: InputProps) =>
        props.filled &&
        css`
          transform: translateY(-2rem);
          font-size: 1.2rem;
        `}
    `
  )};
`;

export const Error = styled(ErrorText)`
  transform: translateY(22px);
`;
