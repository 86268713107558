import React, { FunctionComponent, useState, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import * as Styled from "./Checkbox.styled";
import useError from "../useError";

type Props = {
  name: string;
  defaultChecked?: boolean;
  required?: boolean | string;
  color?: "white" | "black";
};

const Checkbox: FunctionComponent<Props> = ({ children, ...props }) => {
  const { register } = useFormContext();
  const error = useError(props.name);
  const checked = useWatch({ name: props.name });

  return (
    <Styled.Container>
      <input
        id={props.name}
        type="checkbox"
        {...register(props.name, { required: props.required })}
      />
      <Styled.Label htmlFor={props.name}>
        <Styled.Checkbox checked={checked} color={props.color}>
          <Styled.Inner checked={checked} color={props.color} />
        </Styled.Checkbox>
        <Styled.LabelText color={props.color}>{children}</Styled.LabelText>
      </Styled.Label>
      {error && <Styled.Error>{error}</Styled.Error>}
    </Styled.Container>
  );
};

export default Checkbox;
