import styled, { css } from "styled-components";
import ThemeStyle from "../../../styles/ThemeStyle";
import { ErrorText } from "../Form.styled";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: baseline;
  margin-top: 1.5rem;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:active + div {
      transform: scale(0.9);
    }
  }
`;

type CheckboxProps = {
  checked?: boolean;
  color?: "white" | "black";
};

export const Checkbox = styled.div<CheckboxProps>`
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
  border: 2px solid ${ThemeStyle.colors.white};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 3px;
  ${(p: CheckboxProps) =>
    p.color === "black" &&
    css`
      border: 2px solid ${ThemeStyle.colors.black};
    `}
`;

export const Inner = styled.div<CheckboxProps>`
  width: 0;
  height: 0;
  background-color: ${ThemeStyle.colors.white};
  transition: ${ThemeStyle.transition};
  transition-property: width, height;
  ${(p: CheckboxProps) =>
    p.color === "black" &&
    css`
      background-color: ${ThemeStyle.colors.black};
    `}
  ${(props) =>
    props.checked &&
    css`
      width: 1.3rem;
      height: 1.3rem;
    `}
`;

export const LabelText = styled.p<CheckboxProps>`
  font-size: 1.2rem;
  padding-left: 1rem;
  padding-top: 0;
  cursor: pointer;
  line-height: 2rem;
  color: ${ThemeStyle.colors.white};
  ${(p: CheckboxProps) =>
    p.color === "black" &&
    css`
      color: ${ThemeStyle.colors.black};
    `}
  a {
    font-weight: ${ThemeStyle.fontWeight.bold};
    transition: color 0.3s ease-in-out;
    &:hover {
      color: ${ThemeStyle.colors.lime};
    }
  }
`;

export const Label = styled.label`
  position: relative;
  display: flex;
`;

export const Error = styled(ErrorText)`
  left: 2.5rem;
  font-size: 1.4rem;
  transform: translateY(1.7rem);
`;
