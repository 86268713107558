import styled, { css } from "styled-components";
import ThemeStyle from "../../styles/ThemeStyle";
import { AppContainer, mediaDown } from "../../styles/Mixins";
import DesktopBg from "../../images/FormPage/desktop.jpg";
import TabletProBg from "../../images/FormPage/tablet-pro.jpg";
import TabletMiniBg from "../../images/FormPage/tablet.jpg";
import MobileBg from "../../images/FormPage/mobile.jpg";

type FormProps = {
  type?: string | null;
};

export const FormSection = styled.section<FormProps>`
  position: relative;
  background-color: ${ThemeStyle.colors.blue};
  z-index: 2;

  .grecaptcha-badge {
    opacity: 0;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 50rem;
    aspect-ratio: 1;
    border-radius: 50%;
    background: ${ThemeStyle.colors.teal};
    filter: blur(20rem);
    box-shadow: 0 0 10rem 10rem ${ThemeStyle.colors.teal};
    z-index: 2;
  }

  &::before {
    top: 35%;
    left: 0;
  }

  &::after {
    top: 10%;
    right: -6%;
  }

  ${mediaDown(
    ThemeStyle.breakpoints.ultra,
    css`
      &::before {
        top: 40%;
        left: -15%;
      }

      &::after {
        right: -10%;
      }
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.lg,
    css`
      &::before {
        width: 35rem;
        top: 60%;
      }

      &::after {
        top: 5%;
        width: 35rem;
      }
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      &::before,
      &::after {
        content: none;
      }
    `
  )}

  ${(p: FormProps) =>
    p.type === "formPage" &&
    css`
      background: ${ThemeStyle.colors.white} url("${DesktopBg}") no-repeat;
      background-position: center;
      background-size: cover;
      padding-top: 10%;
      min-height: calc(100vh - 10rem);
      overflow: hidden;

      &::before,
      &::after {
        content: none;
        background: rgba(167, 194, 11, 0.3);
        box-shadow: 0 0 10rem 10rem rgba(167, 194, 11, 0.3);
      }

      &::before {
        top: 20%;
        left: -10%;
      }

      &::after {
        background: rgba(2, 176, 218, 0.3);
        box-shadow: 0 0 10rem 10rem rgba(2, 176, 218, 0.3);
        top: 5%;
        right: -10%;
      }

      ${mediaDown(
        ThemeStyle.breakpoints.ultra,
        css`
          &::before {
            top: 20%;
            left: -15%;
          }
        `
      )}
      ${mediaDown(
        ThemeStyle.breakpoints.lg,
        css`
          padding-top: 20%;
          min-height: calc(100vh - 10rem);
          background: ${ThemeStyle.colors.white} url("${TabletProBg}") no-repeat;
          background-position: center;
          background-size: cover;
          &::before {
            top: 40%;
          }
        `
      )}
      ${mediaDown(
        ThemeStyle.breakpoints.md,
        css`
          background: ${ThemeStyle.colors.white} url("${TabletMiniBg}")
            no-repeat;
          background-position: center;
          background-size: cover;
        `
      )}
      ${mediaDown(
        ThemeStyle.breakpoints.sm,
        css`
          background: ${ThemeStyle.colors.white} url("${MobileBg}") no-repeat;
          background-position: center;
          background-size: cover;
        `
      )}
    `};
`;

const MaskBase = css`
  position: absolute;
  width: 50rem;
  aspect-ratio: 1;
  border-radius: 50%;
  filter: blur(20rem);
  z-index: 3;
`;

export const GreenMask = styled.div`
  ${MaskBase};
  right: -10%;
  bottom: 15%;
  background: rgba(167, 194, 11, 0.3);
  box-shadow: 0 0 10rem 10rem rgba(167, 194, 11, 0.3);
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      right: -50%;
    `
  )}
`;

export const BlueMask = styled.div`
  ${MaskBase};
  left: -10%;
  bottom: -30%;
  background: rgba(2, 176, 218, 0.3);
  box-shadow: 0 0 10rem 10rem rgba(2, 176, 218, 0.3);
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      bottom: -40%;
    `
  )}
`;

export const Container = styled.div<FormProps>`
  position: relative;
  ${AppContainer};
  flex-flow: column;
  padding-top: 6rem;
  padding-bottom: 10rem;
  max-width: 100rem;
  z-index: 5;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      padding-bottom: 5rem;
    `
  )}
`;

export const Title = styled.h2<FormProps>`
  font-family: ${ThemeStyle.fonts.second};
  font-weight: ${ThemeStyle.fontWeight.bold};
  color: ${ThemeStyle.colors.white};
  line-height: 1;
  text-align: center;
  font-size: 9rem;
  margin-bottom: 1rem;
  ${(p: FormProps) =>
    p.type &&
    css`
      background: -webkit-linear-gradient(270deg, #208e73 0%, #93bb1b 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 0;
    `};
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      font-size: 6rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 5rem;
      margin-bottom: 0;
    `
  )}
`;

export const Subtitle = styled.p<FormProps>`
  color: ${ThemeStyle.colors.white};
  font-size: 2rem;
  text-align: center;
  margin-top: 2rem;
  ${(p: FormProps) =>
    p.type &&
    css`
      color: ${ThemeStyle.colors.black};
      margin-top: 0;
    `};
`;

export const Line = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -40%);
`;
