import React, { FunctionComponent } from "react";
import * as Styled from "./Input.styled";
import { useFormContext, useWatch } from "react-hook-form";
import { InputProps } from "../FormProps";
import useError from "../useError";

type Props = InputProps & {
  type?: "text" | "email" | "password";
  pattern?: {
    value: RegExp;
    message: string;
  };
  color?: "white" | "black";
  autocomplete?: boolean;
};

const Input: FunctionComponent<Props> = ({
  type = "text",
  color = "white",
  autocomplete = true,
  ...props
}) => {
  const { register } = useFormContext();
  const value = useWatch({ name: props.name });
  const error = useError(props.name);
  const [state, setState] = React.useState<
    "typing" | "error" | "valid" | "empty"
  >("empty");
  const [inputFilled, setInputFilled] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (error) {
      setState((prev) => (prev === "typing" ? prev : "error"));
    } else {
      setState(value ? "valid" : "empty");
    }
  }, [error]);

  const handleAutoFill = () => {
    setInputFilled(true);
  };

  return (
    <>
      <Styled.InputWrapper>
        <Styled.Label
          htmlFor={props.name}
          state={state}
          filled={inputFilled}
          color={color}
        >
          {props.label}
        </Styled.Label>
        <Styled.Input
          id={props.name}
          state={state}
          filled={inputFilled}
          type={type}
          color={color}
          {...register(props.name, {
            required: props.required,
            pattern: props.pattern,
          })}
          autoComplete={autocomplete ? "on" : "off"}
          onFocus={() => {
            setState("typing");
            setInputFilled(true);
          }}
          onBlur={() => {
            setState(error ? "error" : value ? "valid" : "empty");
            setInputFilled(!!value);
          }}
          onAnimationStart={handleAutoFill}
        />
        {error && <Styled.Error>{error}</Styled.Error>}
      </Styled.InputWrapper>
    </>
  );
};

export default Input;
