import styled, { css } from "styled-components";
import ThemeStyle from "../../../styles/ThemeStyle";
import { mediaDown } from "../../../styles/Mixins";

export const SuccessBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8rem;
  background: transparent;
  height: 100%;
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      padding: 4rem;
    `
  )}
`;

type SuccessProps = {
  color?: "white" | "black";
};

export const Title = styled.h2<SuccessProps>`
  font-size: 2.4rem;
  color: ${ThemeStyle.colors.white};
  text-align: center;
  margin-bottom: 2rem;
  ${(p: SuccessProps) =>
    p.color === "black" &&
    css`
      color: ${ThemeStyle.colors.black};
    `};
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      font-size: 2rem;
    `
  )}
`;

export const Message = styled.p<SuccessProps>`
  font-weight: 700;
  font-size: 2rem;
  color: ${ThemeStyle.colors.white};
  margin-bottom: 6rem;
  text-align: center;
  ${(p: SuccessProps) =>
    p.color === "black" &&
    css`
      color: ${ThemeStyle.colors.black};
    `};
`;
