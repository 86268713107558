import { useFormState } from "react-hook-form";
import React from "react";

type UseErrorResponse = false | string;

const checkError = (errors: any, name: string): string | null => {
  const parts = name.split(".");

  parts.forEach((part, index) => {
    if (part.endsWith("]")) {
      part = part.substr(0, part.length - 1);
      const subparts = part.split("[");
      parts.splice(index, 1, ...subparts);
    }
  });
  const error = parts.reduce((object: any, currentName) => {
    if (typeof object === "string" || object === undefined || object === null) {
      return object;
    }
    if (object.hasOwnProperty(currentName)) {
      return object[currentName];
    }
    return null;
  }, errors);

  if (error && error.hasOwnProperty("message")) {
    return error["message"];
  }
  return null;
};

const useError: (name: string) => UseErrorResponse = (name) => {
  const formState = useFormState();
  const [error, setError] = React.useState<UseErrorResponse>(false);

  React.useEffect(() => {
    const error = checkError(formState.errors, name);

    setError(error ?? false);
  }, [formState, name]);

  return error;
};

export default useError;
