import styled, { css } from "styled-components";
import ThemeStyle from "../../styles/ThemeStyle";
import { mediaDown } from "../../styles/Mixins";

export const ErrorText = styled.p`
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 1.6rem;
  color: ${ThemeStyle.colors.error};
`;

export const LabelText = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-5px);
  color: ${ThemeStyle.colors.white};
  font-size: 2rem;
  transition: ${ThemeStyle.transition};
  transition-property: font-size, transform;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      transform: translateY(5px);
    `
  )};
`;
